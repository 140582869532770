<template>
    <div class="card flex-1">
        <header class="border-b border-solid border-gray-300 pb-4 mb-5">
            <h1>{{ $t('settings.general') }}</h1>
        </header>
        <el-form
            ref="form"
            v-loading="$wait.is('loading')"
            label-position="top"
            :model="formData"
            :rules="rules"
            class="px-24"
            element-loading-spinner="el-custom-spinner"
        >
            <el-form-item prop="paperInvoiceCost" :label="$t('settings.paper_invoice_cost')">
                <div class="flex">
                    <money v-model="formData.paperInvoiceCost" class="el-input__inner" v-bind="money" />
                    <span class="bg-gray-200 border border-gray-400 px-4 rounded-tr-sm rounded-br-sm special-height">kr</span>
                </div>
            </el-form-item>

            <el-form-item :label="$t('settings.invoice_remind_cost_type')">
                <el-switch
                    v-model="formData.invoiceRemindCostType"
                    :active-text="$t('settings.percentage')"
                    :inactive-text="$t('settings.amount')"
                    active-value="percentage"
                    inactive-value="amount"
                    inactive-color="rgb(19, 206, 102)"
                    :width="100"
                    @change="invoiceRemindCostChanged"
                />
            </el-form-item>

            <el-form-item :label="$t('settings.invoice_remind_cost_value')" prop="invoiceRemindCost">
                <div v-if="formData.invoiceRemindCostType == 'amount'" class="flex">
                    <money v-model="formData.invoiceRemindCost" class="el-input__inner" v-bind="money" />
                    <span class="bg-gray-200 border border-gray-400 px-5 text-gray-600 rounded-tr-sm rounded-br-sm special-height">kr</span>
                </div>
                <el-input v-else v-model.number="formData.invoiceRemindCost">
                    <template slot="append">
                        %
                    </template>
                </el-input>
            </el-form-item>

            <el-form-item :label="$t('settings.initial_invoice_id')" prop="initialInvoiceId">
                <el-input v-model.number="formData.initialInvoiceId" :disabled="formData.hasInvoices === 1" />
            </el-form-item>

            <el-form-item :label="$t('settings.default_payment_period_for_companies')">
                <el-input-number v-model.number="formData.companyPaymentPeriod" :min="1" :max="90" class="w-full" />
            </el-form-item>

            <el-form-item :label="$t('settings.default_payment_period_for_clients')">
                <el-input-number v-model.number="formData.privatePaymentPeriod" :min="1" :max="90" class="w-full" />
            </el-form-item>

            <div class="flex justify-end">
                <el-button type="primary" :loading="$wait.is('updating')" :disabled="!userCan('update settings')" @click="update">
                    {{ $t('common.update') }}
                </el-button>
            </div>
        </el-form>
    </div>
</template>
<script>
import Vue from 'vue';
import { Money } from 'v-money';

Vue.use(Money);
export default {
    components: { Money },

    data() {
        return {
            hasInvoices: 0,
            money:       {
                decimal:   ',',
                thousands: '',
                precision: 2,
                masked:    true,
            },
            formData: {
                paperInvoiceCost:      0,
                invoiceRemindCostType: 'amount',
                invoiceRemindCost:     0,
                hasInvoices:           false,
            },
            rules: {
                paperInvoiceCost: [],
            },
        };
    },

    beforeCreate() {
        this.$emit('activeSubpage', 'general');
    },

    created() {
        this.getSettings();
    },

    methods: {
        async getSettings() {
            this.$wait.start('loading');
            try {
                const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/settings/economy`);
                this.$wait.end('loading');
                this.formData.paperInvoiceCost = data.paper_invoice_cost / 100;
                this.formData.invoiceRemindCostType = data.invoice_remind_cost_type;
                this.formData.invoiceRemindCost = data.invoice_remind_cost;
                this.formData.companyPaymentPeriod = data.company_payment_period;
                this.formData.privatePaymentPeriod = data.private_payment_period;
                this.formData.initialInvoiceId = data.initial_invoice_id;
                this.hasInvoices = data.has_invoices;
            } catch (error) {
                this.$wait.end('loading');
            }
        },

        update() {
            this.$refs.form.validate(async valid => {
                if (!valid) return false;

                this.$wait.start('updating');

                let remindCost = String(this.formData.invoiceRemindCost).replace(',', '');
                remindCost = this.formData.invoiceRemindCostType === 'amount' ? remindCost / 100 : remindCost;

                try {
                    await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/settings/economy`, {
                        paper_invoice_cost:       this.formData.paperInvoiceCost.replace(',', ''),
                        invoice_remind_cost_type: this.formData.invoiceRemindCostType,
                        invoice_remind_cost:      remindCost,
                        company_payment_period:   this.formData.companyPaymentPeriod,
                        private_payment_period:   this.formData.privatePaymentPeriod,
                        initial_invoice_id:       this.formData.initialInvoiceId,
                    });
                    this.$wait.end('updating');
                    this.$notify.success({
                        title: 'Success',
                    });
                } catch (error) {
                    this.$wait.end('updating');
                }
            });
        },

        invoiceRemindCostChanged() {
            this.formData.invoiceRemindCost = 0;
        },
    },
};
</script>
<style scoped>
.el-input__inner {
    height: 32px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-right: none;
    font-size: 13px;
}

.special-height {
    height: 32px;
}

.el-input-number {
    width: 100%;
}

</style>
